import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FaForward, FaHeart, FaSearch, FaCheck, FaBookmark } from 'react-icons/fa';
import Spinner from '../layout/Spinner';

class Persons extends Component {

    state = {
        search: '',
        searchtype: '',
        books: []
    };

    async componentDidMount() {

    }

    render() {
        return (
            <div className="container">
                <p>مورد - نظام التصنيف والفهرسة والبحث</p>
                <div className="row">
                    <Link to={`/favorites`} className="col-12 m-1 btn btn-outline-secondary btn-sm"><FaHeart />  استعراض بيانات المفضلة </Link>
                    <Link to={`/reserved`} className="col-12 m-1 btn btn-outline-success btn-sm"><FaBookmark />  استعراض بيانات الحجوزات </Link>
                    <Link to={`/borrows`} className="col-12 m-1 btn btn-outline-info btn-sm"><FaCheck /> استعراض بيانات الاعارة  </Link>
                </div>
            </div>
        );
    }
}
export default Persons;
