import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import img from '../../img.jpg';
import Spinner from '../layout/Spinner';
import classnames from 'classnames';

class BooksDetails extends Component {

  state = {
    book: {},
    bookauthors:[]
  };

  async componentDidMount() {
    let bookurl = `https://web2py-dot-abegsnode.appspot.com/library/api/getbookbyid?id=${this.props.match.params.id}`;
    this.getBook(bookurl);
    let bookauthorsurl = `https://web2py-dot-abegsnode.appspot.com/library/api/getbook_authors?bookid=${this.props.match.params.id}`;
    this.getBookAuthors(bookauthorsurl);
  }

  getBook = async url => {
    let data = await fetch(url);
    let json = await data.json();
    this.setState({ book: json.data });
  }
  
  getBookAuthors = async url => {
    let data = await fetch(url);
    let json = await data.json();
    this.setState({ bookauthors: json.data });
  }

  render() {
    const { book } = this.state;
    if (book) {
      return (
        <div className="card border-secondary m-3  mx-auto" key={book.id}>
          <div className="card-header">
            <Link to={`/`} className="btn btn-outline-primary btn-sm"> <i className="fas fa-arrow-circle-right" />عودة</Link>
          </div>
          <div className="card-body text-secondary">
            <h4 className="card-text text-justify">العنوان : {book.title}</h4>
            <p className="card-text text-justify">نوع الوثيقة : {book.typedis}</p>
            <p className="card-text text-justify">تصنيف ديوي : {book.dewy}</p>
            <p className="card-text text-justify">أحرف التمييز : {book.different}</p>
            <p className="card-text text-justify"> الرقم المعياري الدولي : {book.isbn}</p>
            <p className="card-text text-justify"> الحجم : {book.size}</p>
            <p className="card-text text-justify"> عدد الصفحات : {book.pages}</p>
            <p className="card-text text-justify"> السلسلة : {book.series}</p>
            <p className="card-text text-justify"> المدى : {book.range}</p>
            <p className="card-text text-justify"> العدد : {book.issue}</p>
            <p className="card-text text-justify"> الطبعة : {book.edition}</p>
            <p className="card-text text-justify"> عدد النسخ : {book.copies}</p>
            <p className="card-text text-justify"> الناشر : {book.publisherdis}</p>
            <p className="card-text text-justify"> سنة الاصدار هجري : {book.hijry_year}</p>
            <p className="card-text text-justify"> سنة الاصدار ميلادي : {book.gregorian_year}</p>
            <p className="card-text text-justify"> الموضوعات : {book.subjects}</p>
            <p className="card-text text-justify"> بيان المسؤولية : {book.response_name}</p>
          </div>
          <div className="card-footer">
            <h4>بيانات المؤلف</h4>
            <ul className="list-group list-group-flush">
            {this.state.bookauthors && this.state.bookauthors.length > 0 ? this.state.bookauthors.map(bookauthor => (
              <li class="list-group-item"  key={bookauthor.id}>{bookauthor.author} - {bookauthor.author_role} </li>
              )) : <div>لا يوجد نتائج </div>}
            </ul>
          </div>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }
}
export default BooksDetails;