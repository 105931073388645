import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../layout/Spinner';

class ReservedDetails extends Component {

    state = {
        book: {},
    };

    async componentDidMount() {
        const { isAuthed, userId, token } = this.props;
        if (isAuthed && userId && token) {
            let bookurl = `https://web2py-dot-abegsnode.appspot.com/library/api/getuserreserveddetails?_token=${token}&id=${this.props.match.params.id}`;
            this.getBook(bookurl);
        } else {
            alert("يجب تسجيل الدخول");
            this.props.history.push("/login");
        }
    }

    getBook = async url => {
        let data = await fetch(url);
        let json = await data.json();
        this.setState({ books: json.reservedbooks });
    }

    render() {
        const { books } = this.state;
        if (books) {
            return (
                <div className="row">
                    {this.state.books && this.state.books.length > 0 ? this.state.books.map(book => (
                        <div className="card border-secondary m-3  mx-auto" style={{ width: "18rem" }} key={book.id}>
                            <div className="card-header">{book.title}</div>
                            <div className="card-body text-secondary">
                                <p className="card-text text-justify">نوع الوثيقة : {book.type}</p>
                                <p className="card-text text-justify">تصنيف ديوي : {book.dewy}</p>
                                <p className="card-text text-justify">احرف التمييز : {book.different}</p>
                                <p className="card-text text-justify">تاريخ الطلب : {book.requestdate}</p>
                                <p className="card-text text-justify">تاريخ الأنتهاء: {book.reserveddate}</p>
                            </div>
                        </div>
                    )) : <div>لا يوجد نتائج </div>}
                </div>
            );
        } else {
            return <Spinner />;
        }
    }
}
export default ReservedDetails;