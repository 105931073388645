import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  FaArrowCircleLeft,
  FaArrowCircleRight,
} from "react-icons/fa";
import Spinner from "../layout/Spinner";

class Reservedrequests extends Component {

  state = {
    reserved: []
  };

  async componentDidMount() {
    const { isAuthed, userId, token } = this.props;
    if (isAuthed && userId && token) {
      let url = "https://web2py-dot-abegsnode.appspot.com/library/api/getuserreserved?_token=" + token;
      let data = await fetch(url);
      let json = await data.json();
      this.setState({ reserved: json.reserved });
    } else {
      alert("يجب تسجيل الدخول");
      this.props.history.push("/login");
    }
  }

  render() {
    const { reserved } = this.state;
    if (reserved) {
      return (
        <div className="container">
          <p>قائمة طلبات الحجوزات</p>
          <div className="row">
            {this.state.reserved && this.state.reserved.length > 0 ? this.state.reserved.map(rec => (
              <div className="card border-secondary m-3  mx-auto" style={{ width: "18rem" }} key={rec.id}>
                <div className="card-header">{rec.requeststatus}</div>
                <div className="card-body text-secondary">
                  <p className="card-text text-justify">اسم الشخص : {rec.person}</p>
                  <p className="card-text text-justify">تاريخ الطلب : {rec.requestdate}</p>
                </div>
                <div className="card-footer">
                  <div className="row">
                    <Link to={`/persons/`} className="col-5 m-1 btn btn-outline-primary btn-sm"><FaArrowCircleRight />  الملف الشخصي </Link>
                    <Link to={`/reserveddetails/${rec.id}`} className="col-5 m-1 btn btn-outline-success btn-sm"> التفاصيل <FaArrowCircleLeft /></Link>
                  </div>
                </div>
              </div>
            )) : <div>لا يوجد نتائج </div>}
          </div>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }
}
export default Reservedrequests;