import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FaForward, FaHeart, FaSearch, FaCheck, FaBookmark} from 'react-icons/fa';
import Spinner from '../layout/Spinner';
import Alert from '../layout/Alert';

const searchUrl = "https://web2py-dot-abegsnode.appspot.com/library/api/getlibrarybooks?searchtype=";

class Books extends Component {

  state = {
    search: '',
    searchtype: '',
    alert:false,
    books: []
  };

  async componentDidMount() {
    let url = 'https://web2py-dot-abegsnode.appspot.com/library/api/getlibrarybooks';
    this.getData(url);
  }

  getData = async url => {
    let data = await fetch(url);
    let json = await data.json();
    if (json.librarybooks.length > 0) {
      this.setState({ books: json.librarybooks });
    }
    else {
      this.setState({ books: null })
    }
  }

  onSearch = () => {
    if (this.state.searchtype > 0 && this.state.search) {
      let url = `${searchUrl}${this.state.searchtype}&bookstags=${this.state.search}`
      this.getData(url)
    } else {
      alert("ادخل البحث بالشكل الصحيح")
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  saveFavorite = async (bookid, e) => {
    const { isAuthed, userId, token } = this.props;
    if(isAuthed && bookid && userId){
      let url = 'https://web2py-dot-abegsnode.appspot.com/library/api/getpersondata?_token=' + token;
      let data = await fetch(url);
      let json = await data.json();
      let personid = json.data.id
      if(personid > 0 ){
        let url = 'https://web2py-dot-abegsnode.appspot.com/library/api/savepersonbooks?_token=' + token + '&personid='+ personid + '&bookid=' + bookid;
        let data = await fetch(url);
        let json = await data.json();
        if (json.data === 2) {
          alert("هذا الكتاب موجود لديكم مسبقا في المفضلة ... ");
        }
        else if(json.data === 1) {
          alert("تم اضافة الكتاب للمفضلة ... ");
        }
        else if (json.data === 0) {
          alert("هناك مشكلة حصلت الرجاء المحاولة لاحقا ... ");
        }
      }
      else{
        alert("البيانات الشخصية غير صحيحة يجب التأكد منها");
      }
    }
    else{
      alert('يجب تسجيل الدخول');
      this.props.history.push('/login');
    }
  }

  saveReserved = async (bookid, e) => {
    const { isAuthed, userId, token } = this.props;
    if(isAuthed && bookid && userId){
      let url = 'https://web2py-dot-abegsnode.appspot.com/library/api/getpersondata?_token=' + token;
      let data = await fetch(url);
      let json = await data.json();
      let personid = json.data.id
      if(personid > 0 ){
        let url = 'https://web2py-dot-abegsnode.appspot.com/library/api/savereservedbooks?_token=' + token + '&personid='+ personid + '&bookid=' + bookid;
        let data = await fetch(url);
        let json = await data.json();
        if (json.data === 3) {
          alert("الحد الأعلى المسموح به لحجز الكتب 5 كتب فقط ....");
        }
        else if(json.data === 2) {
          alert("هذه الوثيقة تم اضافتها سابقا ..... ");
        }
        else if (json.data === 4) {
          alert("هذا الكتاب غير متاح حاليا محجوز أو معار لشخص آخر");
        }
        else if (json.data === 1) {
          alert("تم إضافة الوثيقة للحجز بنجاح .... ");
        }
        else if (json.data === 0) {
          alert("هناك مشكلة حصلت الرجاء المحاولة لاحقا ... ");
        }
      }
      else{
        alert("البيانات الشخصية غير صحيحة يجب التأكد منها");
      }
    }
    else{
      alert('يجب تسجيل الدخول');
      this.props.history.push('/login');
    }
  }

  saveBorrows = async (bookid, e) => {
    const { isAuthed, userId, token } = this.props;
    if(isAuthed && bookid && userId){
      let url = 'https://web2py-dot-abegsnode.appspot.com/library/api/getpersondata?_token=' + token;
      let data = await fetch(url);
      let json = await data.json();
      let personid = json.data.id
      if(personid > 0 ){
        let url = 'https://web2py-dot-abegsnode.appspot.com/library/api/saveborrowsbooks?_token=' + token + '&personid='+ personid + '&bookid=' + bookid;
        let data = await fetch(url);
        let json = await data.json();
        if (json.data === 3) {
          alert("الحد الأعلى المسموح به للإعارة ٣ كتب فقط ....");
        }
        else if(json.data === 4) {
          alert("هذا الكتاب غير متاح حاليا محجوز أو معار لشخص آخر");
        }
        else if (json.data === 2) {
          alert("هذه الوثيقة تم اضافتها سابقا ..... ");
        }
        else if (json.data === 1) {
          alert("تم إضافة الوثيقة للإعارة بنجاح .... ");
        }
        else if (json.data === 0) {
          alert("هناك مشكلة حصلت الرجاء المحاولة لاحقا ... ");
        }
      }
      else{
        alert("البيانات الشخصية غير صحيحة يجب التأكد منها");
      }
    }
    else{
      alert('يجب تسجيل الدخول');
      this.props.history.push('/login');
    }
  }


  render() {
    return (
      <div className="container">
        <p>مورد - نظام التصنيف والفهرسة والبحث</p>
        <div className="row bg-light border">
          <div className="col-12 col-sm-6">
            <div className="input-group">
              <input name="search" type="text" className="form-control " placeholder="اكتب الموضوع المراد البحث عنه" onChange={this.onChange} value={this.state.search} />
              <div className="input-group-prepend">
                <div className="btn-group">
                  <button className="btn btn-secondary " onClick={this.onSearch} disabled={!this.state.searchtype}>بحث</button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6" >
            <select className="form-control" name="searchtype" onChange={this.onChange} value={this.state.searchtype}>
              <option value="0">أختر مجال البحث</option>
              <option value="1">البحث بالعنوان</option>
              <option value="2">البحث بالموضوع</option>
              <option value="3">البحث بالمؤلف</option>
              <option value="4">البحث بسنة النشر هجري</option>
              <option value="5">البحث بسنة النشر ميلادي</option>
              <option value="6">البحث بتصنيف ديوي</option>
              <option value="7">البحث بأحرف التمييز</option>
              <option value="8">البحث بالناشر</option>
              <option value="9">البحث بالنوع</option>
              <option value="10">البحث بالسلسلة</option>
            </select>
          </div>
        </div>
        <div className="row">
        {this.state.alert ? <Alert message="aaaaaaa" messageType="success"/> : null}
          {this.state.books && this.state.books.length > 0 ? this.state.books.map(book => (
            <div className="card border-secondary m-3  mx-auto" style={{ width: "18rem" }} key={book.id}>
              <div className="card-header">{book.title}</div>
              <div className="card-body text-secondary">
                <p className="card-text text-justify">نوع الوثيقة : {book.type}</p>
                <p className="card-text text-justify">تصنيف ديوي : {book.dewy}</p>
                <p className="card-text text-justify">احرف التمييز : {book.different}</p>
              </div>
              <div className="card-footer">
                <div className="row">
                  <Link to={`/bookdetails/${book.id}`} className="col-5 m-1 btn btn-outline-primary btn-sm"><FaSearch /> عرض</Link>
                  <button className="col-5 m-1 btn btn-outline-secondary btn-sm" onClick={(e) => this.saveFavorite(book.id, e)}><FaHeart /> مفضلة </button>
                  <button className="col-5 m-1 btn btn-outline-success btn-sm" onClick={(e) => this.saveReserved(book.id, e)}><FaBookmark /> حجز </button>
                  <button className="col-5 m-1 btn btn-outline-info btn-sm" onClick={(e) => this.saveBorrows(book.id, e)}><FaCheck /> اعارة </button>
                </div>
              </div>
            </div>
          )) : <div>لا يوجد نتائج </div>}
        </div>
      </div>
    );
  }
}
export default Books;
