import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import AppNavbar from './components/layout/AppNavbar';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import Books from './components/books/Books';
import BooksDetails from './components/books/BooksDetails';
import Persons from './components/persons/Persons';
import Reservedrequests from './components/persons/Reservedrequests';
import Borrowsrequests from './components/persons/Borrowsrequests';
import Favoriterequests from './components/persons/Favoriterequests';
import ReservedDetails from './components/persons/ReservedDetails';
import BorrowsDetails from './components/persons/BorrowsDetails';


class App extends Component {

  state={data:null,isAuthed:false,email:null};

  parentMethod=(data)=>{
    let isAuthed = data.userId ? true :false;
    this.setState({data, isAuthed, token:data.token, userId:data.userId, email:data.email});
  }

  parenClear=()=>{
    this.setState({data:null,isAuthed:false,email:null});
  }

  render() {
    return (

      <Router>
        <div className="App">
          <AppNavbar isAuthed={this.state.isAuthed} email={this.state.email} parenClear={this.parenClear}/>
          <div className="container">
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => <Books {...props} isAuthed={this.state.isAuthed} userId={this.state.userId} token={this.state.token} />}
              />
              <Route
                exact
                path="/bookdetails/:id"
                component={BooksDetails}
              />
              <Route
                exact
                path="/login"
                render={(props) => <Login {...props} isAuthed={this.state.isAuthed} parentMethod={this.parentMethod} />}
              />
              <Route
                exact
                path="/register"
                component={Register}
              />
              <Route
                exact
                path="/persons"
                render={(props) => <Persons {...props} isAuthed={this.state.isAuthed} userId={this.state.userId} token={this.state.token} />}
              />
              <Route
                exact
                path="/reserved"
                render={(props) => <Reservedrequests {...props} isAuthed={this.state.isAuthed} userId={this.state.userId} token={this.state.token} />}
              />
              <Route
                exact
                path="/reserveddetails/:id"
                render={(props) => <ReservedDetails {...props} isAuthed={this.state.isAuthed} userId={this.state.userId} token={this.state.token} />}
              />
              <Route
                exact
                path="/borrows"
                render={(props) => <Borrowsrequests {...props} isAuthed={this.state.isAuthed} userId={this.state.userId} token={this.state.token} />}
              />
              <Route
                exact
                path="/borrowsdetails/:id"
                render={(props) => <BorrowsDetails {...props} isAuthed={this.state.isAuthed} userId={this.state.userId} token={this.state.token} />}
              />
              <Route
                exact
                path="/favorites"
                render={(props) => <Favoriterequests {...props} isAuthed={this.state.isAuthed} userId={this.state.userId} token={this.state.token} />}
              />
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
