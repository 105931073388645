import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  FaForward,
  FaHeart,
  FaSearch,
  FaCheck,
  FaBookmark,
  FaBackward,
  FaArrowCircleRight,
} from "react-icons/fa";
import Spinner from "../layout/Spinner";

class Favoriterequests extends Component {
  state = {
    books: []
  };

  async componentDidMount() {
    const { isAuthed, userId, token } = this.props;
    if (isAuthed && userId && token) {
      let url = "https://web2py-dot-abegsnode.appspot.com/library/api/getpersonbooksdata?_token=" + token;
      let data = await fetch(url);
      let json = await data.json();
      console.log(json);
      this.setState({ books: json.personbooks });
    } else {
      alert("يجب تسجيل الدخول");
      this.props.history.push("/login");
    }
  }

  render() {
    const { books } = this.state;
    if (books) {
      return (
        <div className="container">
          <p>قائمة الكتب في المفضلة</p>
          <div className="row">
            {this.state.books && this.state.books.length > 0 ? this.state.books.map(book => (
              <div className="card border-secondary m-3  mx-auto" style={{ width: "18rem" }} key={book.id}>
                <div className="card-header">{book.title}</div>
                <div className="card-body text-secondary">
                  <p className="card-text text-justify">نوع الوثيقة : {book.type}</p>
                  <p className="card-text text-justify">تصنيف ديوي : {book.dewy}</p>
                  <p className="card-text text-justify">احرف التمييز : {book.different}</p>
                </div>
                <div className="card-footer">
                  <div className="row">
                    <Link to={`/persons/`} className="col-5 m-1 btn btn-outline-primary btn-sm"><FaArrowCircleRight />  عودة للقائمة </Link>
                  </div>
                </div>
              </div>
            )) : <div>لا يوجد نتائج </div>}
          </div>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }
}
export default Favoriterequests;
