import React, { Component } from 'react';
import Alert from '../layout/Alert';
import jwtDecode from 'jwt-decode'
class Login extends Component {

  state = {
    email: '',
    password: ''
  };

  onSubmit = async e => {
    e.preventDefault();
    const { email, password } = this.state;
    let url = `https://web2py-dot-abegsnode.appspot.com/library/api/login_and_take_token?email=${email}&password=${password}`;
    let data = await fetch(url);
    let json = await data.json();
    this.setState({ token: json.token });
    let userId = jwtDecode(this.state.token).user.id;
    this.props.parentMethod({ userId, token: json.token, email: this.state.email });
    this.props.history.push('/');
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    let message, messageType
    return (
      <div className="row">
        <div className="col-md-6 mx-auto">
          <div className="card">
            <div className="card-body">
              {message ? (
                <Alert message={message} messageType={messageType} />
              ) : null}
              <h4 className="text-center pb-4 pt-3">
                <span className="text-primary">
                  <i className="fas fa-lock" /> تسجيل الدخول
                </span>
              </h4>
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <label htmlFor="email">البريد الإلكتروني</label>
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    required
                    value={this.state.email}
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password">كلمة المرور</label>
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    required
                    value={this.state.password}
                    onChange={this.onChange}
                  />
                </div>
                <input
                  type="submit"
                  value="تسجيل الدخول"
                  className="btn btn-primary btn-block"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Login;
